import { useState, useEffect } from 'react';
import constate from 'constate';
import { firestore } from '../firebase';

function useLeadersState() {
  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    firestore
      .collection('users')
      .where('rank', '==', 'ledare')
      .get()
      .then((snapshot) => {
        const leadersArray = [];
        snapshot.docs.forEach((doc) => {
          leadersArray.push(doc.data());
        });
        setLeaders(leadersArray);
      });
  }, []);

  function getLeadersById(leaderIds) {
    return leaders.filter((leader) => leaderIds.includes(leader.uid));
  }

  return { getLeadersById, leaders };
}

// 2️⃣ Wrap your hook with the constate factory
export const [LeadersProvider, useLeadersContext] = constate(useLeadersState);
