// import Icons from 'views/Icons.js';
import UsersPage from 'views/Users.js';
import UserPage from 'views/User.js';
import AddEventPage from 'views/AddEvent.js';
import EditEventPage from 'views/EditEvent.js';

var routes = [
  // {
  //   path: '/icons',
  //   name: 'Icons',
  //   icon: 'nc-icon nc-diamond',
  //   component: Icons,
  //   layout: '/admin',
  // },
  {
    path: '/users/:id',
    name: 'Users',
    icon: 'nc-icon nc-single-02',
    component: UserPage,
    layout: '/admin',
    hideInMenu: true,
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'nc-icon nc-single-02',
    component: UsersPage,
    layout: '/admin',
  },
  {
    path: '/events/:id',
    name: 'Events',
    icon: 'nc-icon nc-single-02',
    component: EditEventPage,
    layout: '/admin',
    hideInMenu: true,
  },
  {
    path: '/events',
    name: 'Events',
    icon: 'nc-icon nc-calendar-60',
    component: AddEventPage,
    layout: '/admin',
  },
];
export default routes;
