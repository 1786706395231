import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
import 'assets/demo/demo.css';
import 'index.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import './firebaseConfig';

import { LeadersProvider } from './state/leaders';
import App from 'App';

ReactDOM.render(
  <LeadersProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </LeadersProvider>,
  document.getElementById('root'),
);
