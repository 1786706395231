import React, { useEffect, useState } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
} from 'reactstrap';

import { firestore } from '../firebase';

function User(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const id = props.match.params.id;

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const userRef = firestore.collection('users').doc(id);
    userRef.get().then((doc) => setCurrentUser(doc.data()));
  }, [id]);

  if (!currentUser) {
    return null;
  }

  function handleChange(e) {
    const nextCurrentUser = { ...currentUser, [e.target.name]: e.target.value };
    setCurrentUser(nextCurrentUser);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const userRef = firestore.collection('users').doc(id);
    userRef.update(currentUser);
  }

  return (
    <>
      <div className='content'>
        <Row>
          <Col md='4'>
            <Card className='card-user'>
              <div className='image'>
                <img
                  alt='...'
                  src={require('assets/img/damir-bosnjak.jpg').default}
                />
              </div>
              <CardBody>
                <div className='author'>
                  <a href='/' onClick={(e) => e.preventDefault()}>
                    <img
                      alt='...'
                      className='avatar border-gray'
                      src={currentUser.image}
                      style={{ objectFit: 'cover' }}
                    />
                    <h5 className='title'>{currentUser.name}</h5>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md='8'>
            <Card className='card-user'>
              <CardHeader>
                <CardTitle tag='h5'>Edit Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className='pr-1' md='6'>
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          value={currentUser.name}
                          placeholder='Name'
                          type='text'
                          name='name'
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-1' md='6'>
                      <FormGroup>
                        <label>Email / Username</label>
                        <Input
                          value={currentUser.email}
                          placeholder='Username'
                          onChange={handleChange}
                          type='email'
                          name='email'
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pr-1' md='4'>
                      <FormGroup>
                        <label>
                          Rank (Current:{' '}
                          {currentUser.rank.charAt(0).toUpperCase() +
                            currentUser.rank.slice(1)}
                          )
                        </label>

                        <Input
                          type='select'
                          name='rank'
                          value={currentUser.rank}
                          onChange={handleChange}>
                          <option value='user'>User</option>
                          <option value='ledare'>Ledare</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className='update ml-auto mr-auto'>
                      <Button
                        className='btn-round'
                        color='primary'
                        onClick={toggle}
                        type='submit'>
                        Update Profile
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Profile Updated</ModalHeader>
            <Button color='primary' onClick={toggle}>
              CLOSE
            </Button>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default User;
