import React from 'react';
// reactstrap components

import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, Modal, ModalHeader } from 'reactstrap';

import { firestore, Timestamp } from '../firebase'
import { useState } from 'react';
import AllEvents from 'components/AllEvents';
import EventForm from 'components/EventForm';
import { useLeadersContext } from '../state/leaders';
import dayjs from 'dayjs'; // ES 2015
import dayjsRecur from 'dayjs-recur';
dayjs.extend(dayjsRecur);

const initialEventState = {
  type: 'Nattfotboll',
  location: '',
  date: '',
  time_start: '',
  time_end: '',
  leaders: [],
  attendees: [],
  description: '',
};

export default function AddEvent() {
  const [weeks, setWeeks] = useState(0);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [eventInfo, setEventInfo] = useState(initialEventState);

  const { leaders } = useLeadersContext();

  // use this useeffect if you want to get ALL events (docs)
  function handleChange(e) {
    if (e.target.name === 'leaderSwitch') {
      let nextLeaders = [...eventInfo.leaders];
      if (e.target.checked) {
        nextLeaders.push(e.target.value);
      } else {
        nextLeaders = nextLeaders.filter((id) => id !== e.target.value);
      }
      const nextEventInfo = { ...eventInfo, leaders: nextLeaders };
      setEventInfo(nextEventInfo);
    } else {
      const nextEventInfo = { ...eventInfo, [e.target.name]: e.target.value };
      setEventInfo(nextEventInfo);
    }
  }
  // useEffect(() => {
  //   console.log(eventInfo);
  // }, [eventInfo]);
  function handleSubmit(e) {
    e.preventDefault();
    for (let i = 0; i <= weeks; i++) {
      const dayj = dayjs(eventInfo.date)
        .add(7 * i, 'days')
        .format('YYYY-MM-DD');

      firestore.collection('events').doc().set({
        type: eventInfo.type,
        location: eventInfo.location,
        date: dayj,
        time_start: eventInfo.time_start,
        time_end: eventInfo.time_end,
        leaders: eventInfo.leaders,
        attendees: eventInfo.attendees,
        description: eventInfo.description,
        created_at: Timestamp.now(),
      });
    }
    setEventInfo(initialEventState);
    setWeeks(0);
  }
  function validateForm() {
    return eventInfo.type && eventInfo.location && eventInfo.date && eventInfo.time_start && eventInfo.time_end;
  }
  return (
    <>
      <div className='content'>
        <Row>
          <Col md='6'>
            <Card className='card-user'>
              <CardHeader>
                <CardTitle tag='h5'>Add Event</CardTitle>
              </CardHeader>
              <CardBody>
                <EventForm
                  toggle={toggle}
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                  eventInfo={eventInfo}
                  leaders={leaders}
                  submitText='Add Event'
                  setWeeks={setWeeks}
                  weeks={weeks}
                  validateForm={validateForm}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md='6'>
            <AllEvents />
          </Col>
        </Row>
        <div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Event Added</ModalHeader>
            <Button color='primary' onClick={toggle}>
              CLOSE
            </Button>
          </Modal>
        </div>
      </div>
    </>
  );
}
