import { EventCategories } from './EventCategories';
import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardHeader, CardTitle } from 'reactstrap';
import { firestore } from '../firebase';
import 'firebase/firestore';
import EventCard from './EventCard';

export default function AllEvents() {
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  let today = new Date();
  let startDate = new Date();
  startDate.setDate(today.getDate() - 180);
  let startDateString = startDate.toISOString().split('T')[0];

  useEffect(() => {
    const eventRef = firestore.collection('events');
    const unsubscribe = eventRef
      .where('date', '>=', startDateString)
      .orderBy('date', 'desc')
      .onSnapshot(
        (snapshot) => {
          const events = [];
          snapshot.docs.forEach((doc) => {
            events.push({ ...doc.data(), id: doc.id });
          });
          setAllEvents(events);
        },
        (error) => console.log(error),
      );
    return unsubscribe;
  }, [startDateString]);

  const events = useMemo(() => {
    if (selectedEventTypes.length === 0) {
      return allEvents.slice(0, 30);
    } else {
      return allEvents
        .filter((e) => selectedEventTypes.includes(e.type))
        .slice(0, 30);
    }
  }, [allEvents, selectedEventTypes]);

  return (
    <Card className='card-user'>
      <CardHeader>
        <CardTitle tag='h5'>All Events</CardTitle>
      </CardHeader>
      <EventCategories
        selectedEventTypes={selectedEventTypes}
        setSelectedEventTypes={setSelectedEventTypes}
      />

      {events &&
        events.map((event) => <EventCard key={event.id} event={event} />)}
    </Card>
  );
}
