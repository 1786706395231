import React from 'react';
import {
  Button,
  CustomInput,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap';

export default function EventForm({
  onSubmit,
  onChange,
  eventInfo,
  leaders,
  toggle,
  submitText,
  setWeeks,
  validateForm,
  weeks,
}) {
  function handleWeeks(e) {
    setWeeks(e.target.value);
  }

  leaders = leaders.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Form onSubmit={onSubmit}>
      <Row>
        <Col className='px-3' md='12'>
          <FormGroup>
            <label>Event Type</label>
            <Input
              type='select'
              name='type'
              value={eventInfo.type}
              onChange={onChange}>
              <option>Nattfotboll</option>
              <option>Kvartersfotboll</option>
              <option>Parafotboll</option>
              <option>Maxi</option>
              <option>Tjejträning</option>
              <option>Arenafotboll</option>
              <option>Turnering</option>
              <option>Alblebish Trophy</option>
              <option>Alblebish e-Trophy</option>
              <option>Futsal</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className='px-3' md='12'>
          <FormGroup>
            <label>Location</label>
            <Input
              value={eventInfo.location}
              placeholder='(ex. Tipshallen)'
              onChange={onChange}
              type='text'
              name='location'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          <FormGroup>
            <label>Date</label>
            <Input
              type='date'
              name='date'
              value={eventInfo.date}
              onChange={onChange}
              placeholder='Date'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className='pr-1' md='6'>
          <FormGroup>
            <label>Time from</label>
            <Input
              type='time'
              name='time_start'
              value={eventInfo.time_start}
              onChange={onChange}
              placeholder='time placeholder'
            />
          </FormGroup>
        </Col>
        <Col className='pl-1' md='6'>
          <FormGroup>
            <label>Time to</label>
            <Input
              type='time'
              name='time_end'
              value={eventInfo.time_end}
              onChange={onChange}
              placeholder='time placeholder'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          <FormGroup>
            <label>Leaders</label>

            {leaders &&
              leaders.map((leader) => {
                return (
                  <div key={`leader-${leader.uid}`}>
                    <CustomInput
                      type='switch'
                      id={`leader-${leader.uid}`}
                      name='leaderSwitch'
                      checked={eventInfo.leaders.includes(leader.uid)}
                      value={leader.uid}
                      onChange={onChange}
                      label={leader.name}
                    />
                  </div>
                );
              })}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className='px-3' md='12'>
          <FormGroup>
            <label>Description</label>
            <Input
              type='textarea'
              value={eventInfo.description}
              onChange={onChange}
              name='description'
            />
          </FormGroup>
        </Col>
      </Row>
      {setWeeks && (
        <Row>
          <Col className='pr-1' md='6'>
            <FormGroup>
              <label>Recurring event? Add Extra weeks</label>
              <input
                type='number'
                value={weeks}
                onChange={handleWeeks}
                placeholder='0 (Default)'
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <div className='update ml-auto mr-auto'>
          <Button
            className='btn-round'
            color='primary'
            onClick={toggle}
            disabled={!validateForm()}
            type='submit'>
            {submitText}
          </Button>
        </div>
      </Row>
    </Form>
  );
}
