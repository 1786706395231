import React from 'react';
export function EventCategories({ selectedEventTypes, setSelectedEventTypes }) {
  function handleOnChange(event) {
    if (event.target.checked) {
      setSelectedEventTypes((e) => [...e, event.target.name]);
    } else {
      setSelectedEventTypes((e) => e.filter((i) => i !== event.target.name));
    }
  }

  const eventTypes = [
    'Nattfotboll',
    'Kvartersfotboll',
    'Parafotboll',
    'Maxi',
    'Esports',
    'Tjejträning',
    'Arenafotboll',
    'Turnering',
    'Futsal',
  ];

  return (
    <div
      style={{
        margin: '0 1rem 1rem 0',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '5px',
      }}>
      {eventTypes.map((event, i) => (
        <div
          key={i}
          style={{
            margin: '0 1rem',
            display: 'flex',
            alignItems: 'center',
          }}>
          <input
            type='checkbox'
            value={event}
            name={event}
            checked={selectedEventTypes.includes(`${event}`)}
            onChange={handleOnChange}
            style={{
              marginRight: '0.25rem',
              height: '15px',
              width: '15px',
            }}
          />
          <label
            htmlFor={event}
            style={{
              margin: '0 1rem 0 0',
              fontSize: '13px',
            }}>
            {event}
          </label>
        </div>
      ))}
    </div>
  );
}
