import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { firestore } from '../firebase';

export default function EventCard({ event }) {
  const [modal, setModal] = React.useState(false);

  const confirmDelete = () => {
    firestore.collection('events').doc(event.id).delete();
    setModal(!modal);
  };

  const toggle = () => setModal(!modal);

  function deleteEvent() {
    setModal(!modal);
  }
  return (
    <div
      className={
        event.type === 'Parafotboll'
          ? 'bg-2'
          : event.type === 'Tjejträning'
          ? 'bg-3'
          : event.type === 'Maxi'
          ? 'bg-4'
          : event.type === 'Esports'
          ? 'bg-5'
          : event.type === 'Arenafotboll'
          ? 'bg-6'
          : event.type === 'Kvartersfotboll'
          ? 'bg-7'
          : event.type === 'Turnering'
          ? 'bg-8'
          : event.type === 'Futsal'
          ? 'bg-9'
          : 'bg-primary'
      }
      style={{
        backgroundColor: '#e23d55',
        margin: '0.5rem 1rem',
        borderRadius: '5px',
        display: 'flex',
        padding: '0.5rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#fff',
        fontWeight: 'bold',
        flexWrap: 'wrap',
      }}>
      <div>
        {event.type}
        <br />
        {event.date}
      </div>

      <div>
        <Link
          to={`/admin/events/${event.id}`}
          style={{ margin: 'auto', flex: '1' }}>
          <Button color='secondary'>Edit event</Button>
        </Link>
        <Button color='secondary' style={{ flex: '1' }} onClick={deleteEvent}>
          DELETE EVENT
        </Button>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
        <Button color='primary' onClick={confirmDelete}>
          DELETE
        </Button>
      </Modal>
    </div>
  );
}
