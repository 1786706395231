import firebase from 'firebase/app';

var firebaseConfig = {
  apiKey: 'AIzaSyDDhIbNy_b-6TuHEpHtyps0vsEjdHtRD04',
  authDomain: 'nattfotboll-app.firebaseapp.com',
  projectId: 'nattfotboll-app',
  storageBucket: 'nattfotboll-app.appspot.com',
  messagingSenderId: '1008903002490',
  appId: '1:1008903002490:web:f602b0e06781a7e1569ec3',
  measurementId: 'G-2G50CZBJXF',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebaseConfig;
