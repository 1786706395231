import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

export const firestore = firebase.firestore();
export const Timestamp = firebase.firestore.Timestamp
export const auth = firebase.auth();
auth.languageCode = 'sv_SV';

// Use emulators
if (process.env.REACT_APP_USE_EMULATORS === 'true' || process.env.TEST_WORKER_INDEX) {
  firestore.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099');
}
