import React, { useState, useEffect } from 'react';
import AdminLayout from 'layouts/Admin.js';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Button, FormGroup, Form } from 'reactstrap';
import { firestore, auth } from './firebase';

export default function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      let unsubscribe;
      if (user) {
        unsubscribe = firestore
          .collection('users')
          .doc(user.uid)
          .onSnapshot(
            (doc) => {
              setIsAdmin(doc.data().admin === true);
            },
            (error) => console.log(error),
          );
      } else {
        if (typeof unsubscribe === 'function') {
          // @ts-ignore
          unsubscribe();
          unsubscribe = null;
        }
        setIsAdmin(false);
      }
    });
  }, []);

  function login(e) {
    e.preventDefault();
    auth.signInWithEmailAndPassword(email, password);
  }

  if (isAdmin) {
    return (
      <Switch>
        <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
        <Redirect to='/admin/events' />
      </Switch>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#252a48',
        color: '#fff',
      }}>
      <div className='Login'>
        <h5>Admin login</h5>
        <Form onSubmit={login}>
          <FormGroup>
            <label className='input-label'>Email</label>
            <br />
            <input
              autoFocus
              type='email'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{ width: '250px' }}
            />
          </FormGroup>
          <FormGroup>
            <label className='input-label'>Lösenord</label>
            <br />
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: '250px' }}
            />
          </FormGroup>
          <Button
            className='btn btn-primary'
            block
            type='submit'
            disabled={!email && !password}>
            Logga in
          </Button>
        </Form>
      </div>
    </div>
  );
}
