import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase'

import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, Modal, ModalHeader } from 'reactstrap';
import EventForm from 'components/EventForm';
import AllEvents from 'components/AllEvents';
import { useLeadersContext } from '../state/leaders';

export default function EditEvent(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const id = props.match.params.id;

  const { leaders } = useLeadersContext();

  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    const eventRef = firestore.collection('events').doc(id);
    eventRef.get().then((doc) => setCurrentEvent(doc.data()));
  }, [id]);

  if (!currentEvent) {
    return null;
  }

  function handleChange(e) {
    if (e.target.name === 'leaderSwitch') {
      let nextLeaders = currentEvent.leaders;
      if (e.target.checked) {
        nextLeaders.push(e.target.value);
      } else {
        nextLeaders = nextLeaders.filter((id) => id !== e.target.value);
      }
      const nextCurrentEvent = { ...currentEvent, leaders: nextLeaders };
      setCurrentEvent(nextCurrentEvent);
    } else {
      const nextCurrentEvent = {
        ...currentEvent,
        [e.target.name]: e.target.value,
      };
      setCurrentEvent(nextCurrentEvent);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const eventRef = firestore.collection('events').doc(id);
    eventRef.update(currentEvent);
  }
  function validateForm() {
    return currentEvent.type && currentEvent.location && currentEvent.date && currentEvent.time_start && currentEvent.time_end;
  }
  return (
    <>
      <div className='content'>
        <Row>
          <Col md='6'>
            <Card className='card-user'>
              <CardHeader>
                <CardTitle tag='h5'>Edit Event</CardTitle>
              </CardHeader>
              <CardBody>
                <EventForm
                  toggle={toggle}
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                  eventInfo={currentEvent}
                  leaders={leaders}
                  submitText='Update Event'
                  validateForm={validateForm}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md='6'>
            <AllEvents />
          </Col>
        </Row>
        <div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Event Updated</ModalHeader>
            <Button color='primary' onClick={toggle}>
              CLOSE
            </Button>
          </Modal>
        </div>
      </div>
    </>
  );
}
