import React, { useEffect, useState } from 'react';

// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from 'reactstrap';
import { firestore } from '../firebase';
import { Link } from 'react-router-dom';

function UsersPage() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    firestore
      .collection('users')
      .get()
      .then((snapshot) => {
        const newUsers = [];
        snapshot.forEach((doc) => {
          newUsers.push(doc.data());
        });
        setUsers(newUsers);
      });
  }, []);

  return (
    <>
      <div className='content'>
        <Row>
          <Col md='12'>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>Users</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className='text-primary'>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Rank</th>
                      {/* <th className='text-right'>Salary</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {users
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((user) => {
                        return (
                          <tr key={user.uid}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td style={{ textTransform: 'capitalize' }}>
                              {user.rank}
                            </td>
                            {/* <td className='text-right'>$36,738</td> */}
                            <td>
                              <Link to={`/admin/users/${user.uid}`}>
                                <Button
                                  className='btn'
                                  color='primary'
                                  type='submit'>
                                  Edit Profile
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UsersPage;
